#baguetteBox-overlay {
    display: none;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    z-index: 99999999999999999999999999999999999999;
}

#baguetteBox-overlay.visible {
    opacity: 1;
}

#baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

#baguetteBox-overlay .full-image figure {
    display: inline;
    margin: 0;
    height: 100%;
}

#baguetteBox-overlay .full-image img {
    display: inline-block;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    line-height: 1.8;
    color: #ccc;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: Verdana, Geneva, sans-serif;
}

#baguetteBox-overlay .full-image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
}

#baguetteBox-slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    -webkit-transition: left .4s ease, -webkit-transform .4s ease;
    transition: left .4s ease, -webkit-transform .4s ease;
    transition: left .4s ease, transform .4s ease;
    transition: left .4s ease, transform .4s ease, -webkit-transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
    -webkit-animation: bounceFromRight .4s ease-out;
    animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
    -webkit-animation: bounceFromLeft .4s ease-out;
    animation: bounceFromLeft .4s ease-out;
}

.baguetteBox-button#next-button,
.baguetteBox-button#previous-button {
    top: 50%;
    top: calc(50% - 30px);
    width: 44px;
    height: 60px;
}

.baguetteBox-button {
    position: absolute;
    cursor: pointer;
    outline: 0;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 15%;
    background-color: #323232;
    background-color: rgba(50, 50, 50, 0.5);
    color: #ddd;
    font: 1.6em sans-serif;
    -webkit-transition: background-color .4s ease;
    transition: background-color .4s ease;
}

.baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
    right: 2%;
    display: block !important;
}

.baguetteBox-button#previous-button {
    left: 2%;
    display: block !important;
}

.baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px;
}

.baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0;
}